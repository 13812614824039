<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye borderD"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  id="avcc-direito"
                  :value="form.fields.olhoDireito"
                  :disabled="!canEdit"
                  @input="el => debounceInput('olhoDireito', el.target.value)"
                  @click="setActiveForm('avccDireito')"
                  type="text"
                  class="form-control borderDInput"
                  placeholder="Resultado"
                />
              </div>
              <MultipleEyesOptions
                :form="form"
                :options="options"
                activeInput="avccDireito"
                :setActiveInput="this.setActiveForm"
                @select="({ key, value }) => this.updateForm(key, value)"
                @blur="updateMedicalRecord(form)"
                v-if="activeForm === 'avccDireito' && canEdit"
              />
            </div>
            <div class="form-group">
              <div class="input-eye">
                <p class="tag-eye borderE"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  id="avcc-esquerdo"
                  :value="form.fields.olhoEsquerdo"
                  :disabled="!canEdit"
                  @input="el => debounceInput('olhoEsquerdo', el.target.value)"
                  @click="setActiveForm('avccEsquerdo')"
                  type="text"
                  class="form-control borderEInput"
                  placeholder="Resultado"
                />
              </div>
              <MultipleEyesOptions
                :form="form"
                :options="options"
                activeInput="avccEsquerdo"
                :setActiveInput="this.setActiveForm"
                @select="({ key, value }) => this.updateForm(key, value)"
                @blur="updateMedicalRecord(form)"
                v-if="activeForm === 'avccEsquerdo' && canEdit"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <button
        class="btn blue-underline button-copy center"
        @click="copyEyeValues"
        v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
      >
        <Copy />
      </button>

      <!-- <b-col cols="12" v-if="selectedForm && selectedForm.type !== 'PRE_CONSULTATION'">
        <label for="possui-autorrefrator" class="checkbox">
          <input
            type="checkbox"
            id="possui-autorrefrator"
            :disabled="!canEdit"
            :value="form.fields.possuiAutorrefrator"
            @input="updateForm('possuiAutorrefrator', Boolean(!form.fields.possuiAutorrefrator))"
            @blur="updateMedicalRecord(form)"
          />
          <span :class="`checkmark ${!canEdit ? 'checkmark-disabled' : ''}`">
            <Check />
          </span>
          Possui autorrefrator
        </label>
      </b-col> -->

      <!-- <b-col cols="6" v-if="selectedForm && selectedForm.type !== 'PRE_CONSULTATION'">
        <b-row>
          <b-col cols="4">
            <div class="form-group mb-1">
              <label for="avcc-direito-esferico">Esférico</label>
              <input
                autocomplete="off"
                style="text-align: center"
                id="avcc-direito-esferico"
                :value="form.fields.olhoDireitoEsferico"
                :readonly="!canEdit || !form.fields.possuiAutorrefrator"
                :disabled="!canEdit"
                @input="el => updateForm('olhoDireitoEsferico', el.target.value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control small"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group mb-1">
              <label for="avcc-direito-cilindrico">Cilíndrico</label>
              <input
                autocomplete="off"
                style="text-align: center"
                id="avcc-direito-cilindrico"
                :value="form.fields.olhoDireitoCilindrico"
                :readonly="!canEdit || !form.fields.possuiAutorrefrator"
                :disabled="!canEdit"
                @input="el => updateForm('olhoDireitoCilindrico', el.target.value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control small"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group mb-1">
              <label for="avcc-direito-eixo">Eixo</label>
              <input
                autocomplete="off"
                style="text-align: center"
                id="avcc-direito-eixo"
                :value="form.fields.olhoDireitoEixo"
                :readonly="!canEdit || !form.fields.possuiAutorrefrator"
                :disabled="!canEdit"
                @input="el => updateForm('olhoDireitoEixo', el.target.value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control small"
              />
            </div>
          </b-col>
        </b-row>
      </b-col> -->
      
      <!-- <b-col cols="6" v-if="selectedForm && selectedForm.type !== 'PRE_CONSULTATION'">
        <b-row>
          <b-col cols="4">
            <div class="form-group mb-0">
              <label for="avcc-esquerdo-esferico">Esférico</label>
              <input
                autocomplete="off"
                style="text-align: center"
                id="avcc-esquerdo-esferico"
                :value="form.fields.olhoEsquerdoEsferico"
                :readonly="!canEdit || !form.fields.possuiAutorrefrator"
                :disabled="!canEdit"
                @input="el => updateForm('olhoEsquerdoEsferico', el.target.value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control small"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group mb-0">
              <label for="avcc-esquerdo-cilindrico">Cilíndrico</label>
              <input
                autocomplete="off"
                style="text-align: center"
                id="avcc-esquerdo-cilindrico"
                :value="form.fields.olhoEsquerdoCilindrico"
                :readonly="!canEdit || !form.fields.possuiAutorrefrator"
                :disabled="!canEdit"
                @input="el => updateForm('olhoEsquerdoCilindrico', el.target.value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control small"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group mb-0">
              <label for="avcc-esquerdo-eixo">Eixo</label>
              <input
                autocomplete="off"
                style="text-align: center"
                id="avcc-esquerdo-eixo"
                :value="form.fields.olhoEsquerdoEixo"
                :readonly="!canEdit || !form.fields.possuiAutorrefrator"
                :disabled="!canEdit"
                @input="el => updateForm('olhoEsquerdoEixo', el.target.value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control small"
              />
            </div>
          </b-col>
        </b-row>
      </b-col> -->
    </b-row>
  </FormWrapper>
</template>
<script>
import { track } from '@/utils/mixPanel'
import { debounce } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'
// import Check from '@/assets/icons/check.svg'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    // Check,
    MultipleEyesOptions: () => import('@/components/Attendance/Forms/Components/MultipleEyesOptions'),
    Copy: () => import('@/assets/icons/copy.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      selectedForm: state => state.attendance.form.selected,
      form: state => state.attendance.form.avccAcuidadeVisualComCorrecao,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  data() {
    return {
      activeForm: String,
      options: [
        ['20/10', '20/15', '20/20', '20/25', '20/30'],
        ['20/40', '20/50', '20/70', '20/100', '20/200'],
        ['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'],
        ['MM', 'PL', 'SPL']
      ]
    }
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/avccAcuidadeVisualComCorrecao', ['handleFields', 'handleProps']),
    setActiveForm(FormName) {
      this.activeForm = FormName
    },
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.form.fields.olhoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
      track('copy_eye_values', {
          'module': 'Acuidade Visual Com Correcao'
      });
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.olhoDireito = null
      this.form.fields.olhoEsquerdo = null
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const attendanceData = data.find(el => { return el.type === 'avcc-acuidade-visual-com-correcao'})

      if (!attendanceData?.id) return
      Object.keys(attendanceData.value).map(key => {
        if (attendanceData.value[key]) 
          this.updateForm(`${key}`, attendanceData.value[key])
      })
      await this.updateMedicalRecord(this.form)
    },
  }
}
</script>
<style lang="scss" scoped>
  .borderD {
    border-radius: 8px 0 0 0 !important;
    border-bottom: 0px solid var(--neutral-300) !important;
  }
  .borderE {
    border-radius: 0 0 0 8px !important;
  }
  .borderDInput {
    border-radius: 0 8px 0 0 !important;
    border-bottom: 0px solid var(--neutral-300) !important;
  }
  .borderEInput {
    border-radius: 0 0 8px 0 !important;
  }
</style>